table.basic.table {

  &.fixed {
    table-layout: fixed;
  }

  thead {
    th {
      border-top: 0;
      color: $primaryText;
    }
  }
  tbody {
    td {
      color: $primaryText;
      font-size: 14px;
      &.hover {
        cursor: pointer;
      }
    }
  }
  .actions a {
    color: $primaryColor;

    &:hover {
      cursor: pointer;
    }
  }
}
