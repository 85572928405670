table.basic.contacts.table {
  border-collapse: separate;
  border-spacing: 0 20px;

  thead {
    display: none;
    th {
      border: 0 !important;
    }
  }

  tbody {
    tr {
      box-shadow: 0 6px 10px 2px rgba(0,0,0,0.02);
      transition: box-shadow 0.3s ease-in-out;

      td {
        padding: 20px 12px 18px 23px;
        background: #FCFCFD;
        border: 0;

        &:first-child {
          padding-left: 30px;
          -moz-border-radius-topleft: 8px;
          -moz-border-radius-bottomleft: 8px;
          -webkit-border-top-left-radius: 8px;
          -webkit-border-bottom-left-radius: 8px;
        }

        &:last-child {
          -moz-border-radius-topright: 8px;
          -moz-border-radius-bottomright: 8px;
          -webkit-border-top-right-radius: 8px;
          -webkit-border-bottom-right-radius: 8px;
          padding-right: 30px;
        }

        &.actions {
          width: 150px;
        }
      }

      &.selected {
        background: #FFFFFF;
        box-shadow: 0 3px 20px 10px rgba(0,0,0,0.07);
        transition: box-shadow 0.3s ease-in-out;
      }

    }
  }
}

.contacts {
  ul.filter {
    display: flex;
    list-style: none;
    padding-inline-start: 0;

    li {
      padding-right: 15px;
    }
  }

  &.border {
    border-radius: 8px;
    background: $bgCardColor;

    .name {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-size: 1.25rem;
      background: $white;
    }
  }
}