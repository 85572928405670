.notifications {

  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #ccc !important;
  }

  p {
    .tooltip-wrapper {
      display: inline-block;
      margin-left: 6px;
    }
  }

  thead {
    border-bottom: 1px solid #ccc;
  }
  th {
    font-weight: 500;
    text-align: inherit;
    font-size: 18px;
    color: #000;
    text-align: center;
    .tooltip-wrapper {
      display: inline-block;
      margin-left: 6px;
    }
    .foxdeli-icon {
      font-size: 0.8em;
    }
    img, i {
      margin-right: 6px;
    }
    &.title {
      text-align: left;
    }
  }
  tbody {
    .title {
      font-size: 18px;
      color: #000;
      &:before {
        content: " ";
        background-color: $primaryColor;
        border-radius: 25px;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 25px;
        position: relative;
        top: 2px;
      }
    }
  }
  .category-1 {
      .title {

      }
  }
  .category-2 {
    .title {
      &:before {
        margin-left: 25px;
        width: 9px;
        height: 9px;
        top: -2px;
      }
    }
  }
  .category-3 {
    .title {
      color: #aaa;
      font-weight: 300;
      font-size: 15px;
      &:before {
        margin-left: 50px;
        background-color: #fff;
        border: 2px solid $primaryColor;
        width: 9px;
        height: 9px;
        top: -2px;
      }
    }
  }
}
