.table-loader {
  background-color: $loaderColor;
  border-radius: 8px;
  margin-top: 10px;
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    background-color: $primaryColor;
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    animation: loading 3s linear infinite;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 10%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}