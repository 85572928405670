@import '~foxdeli-shared/scss/colors';

.title {
  color: $grey;
  font-size: 2.5rem;
  // TODO: Generalize line-heights
  line-height: 1.15;
  font-weight: bold;
}

.titleSuffix {
  font-size: 1.375rem;
}

.subtitle {
  font-size: 0.75rem;

  // TODO: Generalize line-heights
  line-height: 1.33;
}

.progressList {
  :global .progress-list-label,
  :global .progress-list-value {
    min-width: 55px;
  }
}
