@import '~foxdeli-shared/scss/colors';

.settings {
  .nav-item {
    a {
      text-decoration: none;

      &:hover {
        color: $magenta;
      }

      &.active {
        color: $magenta;
      }
    }
  }
}

.setting-header {
  @extend .d-flex;
  @extend .mb-3;

  h4 {
    @extend .flex-grow-1;
  }
}

.checkbox-modal {
  @extend .d-flex;
}

.extraServiceItem {
  font-size: 14px;
  margin-bottom: 12px;
  color: $primaryText;
}

.extraServiceItem .extraServiceItemContainer{

}

.extraServiceItem .extraServiceItemContainer .tooltip-wrapper{
  position: absolute;
  left: 0;
  top: 0;
}

.extraServiceItem .extraServiceItemContainer .name {
  margin-left: 10px;
  display: block;
}