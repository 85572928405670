@import './src/sass/_variables';
@import '~bootstrap/scss/mixins';
@import '~foxdeli-shared/scss/colors';

.selectContainer {
  :global .select {
    &__control {
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &--is-focused {
        &,
        &:hover {
          border-color: $blue;
        }
      }

      .select__language-name {
        padding-top: 3px;

        @media (max-width: 990px) {
          display: none;
        }
      }

      .select__language-image {
        @media (max-width: 990px) {
          margin-right: 0;
        }
      }
    }

    &__language-image {
      margin-right: 8px;
    }

    &__value-container {
      @media (max-width: 990px) {
        padding-right: 0;
      }
    }

    &__single-value,
    &__placeholder,
    &__option {
      color: $grey-alt;
      font-size: 0.8125rem;
      font-weight: bold;
      line-height: 1;
      text-transform: uppercase;

      // Resetting react-select styles (uses absolute positioning by default)
      max-width: none;
      left: auto;
      position: static;
      top: auto;
      transform: none;
    }

    &__option {
      border-radius: 6px;

      &--is-focused,
      &--is-selected,
      &:active {
        background: $grey-lighter;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: $grey-alt;
    }

    &__menu {
      border: none;
      border-radius: 6px;
      box-shadow: 0 15px 30px rgba(52, 58, 64, 0.05);
      min-width: 148px;
    }

    &__menu-list {
      padding: 8px;
      overflow-x: hidden;
    }
  }
}

.hideCaret {
  :global {
    .select__indicators, .select__language-name {
      display: none;
    }

    .select__menu-list .select__language-name {
      display: initial;
    }

    .select__value-container {
      justify-content: center;
    }
  }
}
