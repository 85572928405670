@import '~foxdeli-shared/scss/colors';

.inline-form-row {
  .section-title {
    font-weight: 500;
    font-size: 1.0625rem;
    text-transform: none;
    padding-bottom: .938rem;
    margin: 0;
  }

  .section-description {
    font-size: .9375rem;
    font-weight: 400;
    height: 100%;
  }

  .row-items {
    .row-item-content {
      h1:first-child, h2:first-child, h3:first-child {
        margin-top: 0;
      }
    }

    .row-item-title {
      color: $black;
      font-weight: 500;
      padding-bottom: .938rem;
      font-size: .9375rem;
    }

    label {
      margin-top: 0;
      font-size: 0.938rem;
    }
  }
}

@media (min-width: 1400px) {
  .inline-form-row {
    .row-items, .row-description {
      padding: 0 1.25rem;
    }
  }
}