[tooltip] {
  position:relative;

  &::before {
    content: "";
    position: absolute;
    left:40%;
    margin-top: -2px;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0,0,0,0.7) transparent transparent transparent;
    z-index: 99;
    opacity:0;
  }

  &::after {
    content: attr(tooltip);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    margin-top: -2px;
    color: #fff;
    font-size: 12px;
    font-weight: 300 !important;
    white-space: nowrap;
    border-radius: 4px;
    pointer-events: none;
    padding: 2px 4px;
    line-height: initial;
    z-index: 99;
    opacity: 0;
  }

  &:hover::after, &:hover::before {
    opacity:1
  }
}

[tooltip-position='left'] {
  &::before{
    left:0%;
    top:50%;
    margin-left:-12px;
    transform:translatey(-50%) rotate(-90deg)
  }

  &::after{
    left:0%;
    top:50%;
    margin-left:-8px;
    transform: translateX(-100%) translateY(-50%);
  }
}

[tooltip-position='right'] {
  &::before{
    left:100%;
    top:50%;
    margin-left:1px;
    transform:translatey(-50%) rotate(90deg)
  }

  &::after{
    left:100%;
    top:50%;
    margin-left:8px;
    transform: translateX(0%) translateY(-50%);
  }
}

[tooltip-position='top'] {
  &::before{
    left:50%;
  }

  &::after{
    left:50%;
  }
}

[tooltip-position='bottom'] {
  &::before{
    top:100%;
    margin-top:8px;
    transform: translateX(-50%) translatey(-100%) rotate(-180deg)
  }

  &::after{
    top:100%;
    margin-top:8px;
    transform: translateX(-50%) translateY(0%);
  }
}