@import '~foxdeli-shared/scss/colors';

.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: $grey-alt;
    cursor: auto;
  }

  .count {
    padding-bottom: 0;
    font-size: 1.5em;
  }
}