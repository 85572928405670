@import '~foxdeli-shared/scss/colors';

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 600px;
  z-index: 1;
  background: rgba(248, 249, 250, 0.5);
  backdrop-filter: blur(16px);
  border-radius: 8px;
  z-index: 10;
  padding-top: 38px;
}

.upsellState {
  width: 480px;
  height: 524px;
  padding: 32px 60px;
  background: #fff;
  border-radius: 8px;
}

.title {
  margin-top: 65px;
  color: $grey;
  font-size: 1.375rem;
  font-weight: 700;
}

.description {
  font-size: 0.9375rem;
}
