@import '~foxdeli-shared/scss/colors';

.fox-loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;

    .fox-loader {
        width: 100%;
        height: 200px;
        position: sticky;
        top: 50%;
        z-index: 100;
        display: grid;
        justify-content: center;
        align-items: flex-start;
        justify-items: center;
        margin-top: 1rem;

        &-text {
            font-size: 1.1rem;
            color: $magenta;
            font-weight: 500;
            text-transform: lowercase;
            text-shadow: 0 0 10px $white;
        }

        &-circle {
            display: grid;
            align-content: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-left: -45px;
            margin-top: -45px;
            background: rgba(248, 249, 250, 0.5);

            box-shadow: 0 0 0 0 $magenta;
            animation: pulse 2s infinite;
        }

        &-circle img {
            max-width: 106px;
        }

        &-line-mask {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 30px;
            margin-left: -45px;
            margin-top: -45px;
            overflow: hidden;
            transform-origin: 40px 40px;
            animation: rotate 1.2s infinite linear;
        }

        &-line-mask .fox-loader-line {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 4px $magenta;
        }

        @keyframes rotate{ 0% {transform: rotate(0deg);} 100% {transform: rotate(360deg);}}

        @keyframes loading {
            from {left: -200px; width: 10%;}
            50% {width: 30%;}
            70% {width: 70%;}
            80% { left: 50%;}
            95% {left: 120%;}
            to {left: 100%;}
        }

        @keyframes pulse {
            0% { box-shadow: 0 0 0 0 $magenta }
            70% { box-shadow: 0 0 0 15px rgba(255, 89, 0, 0); }
            100% { box-shadow: 0 0 0 0 rgba(255, 89, 0, 0); }
        }

        @keyframes text-toggle {
            0% { display: initial; opacity: 0; }
            50% { display: initial; opacity: .5; }
            70% { display: initial; opacity: 1; }
            90% { display: initial; opacity: 1; }
            100% { display: none; opacity: 0; }
        }
    }
}

.fox-loader-wrapper--overlay {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .75);
}