@import '~foxdeli-shared/scss/colors';

.emptyState {
  display: block;
}

.title {
  color: $grey;
  font-size: 1.375rem;
  font-weight: 700;
}

.description {
  font-size: 0.9375rem;
  max-width: 400px;
}
