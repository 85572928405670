@import '~foxdeli-shared/scss/colors';

.welcomeModal {
  font-size: 0.9375rem;
  max-width: 584px !important;
}

.closeButton {
  background: none;
  border: none;
  box-shadow: none;
  outline: transparent;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 1;

  &:focus {
    outline: transparent;
  }
}

.title {
  color: $grey;
  font-size: 1.625rem;
  font-weight: 700;
}

.footnote {
  font-size: 0.8125rem;

  b {
    color: $grey;
    font-weight: 500;
  }
}