@import '~bootstrap/scss/bootstrap.scss';
@import '~react-toastify/dist/ReactToastify.css';

@import "./sass/_variables.scss";
@import "./sass/_fonts.scss";

@import "~foxdeli-shared/scss/colors";
@import "./2020/scss/_utils.scss";
@import "./2020/scss/_buttons.scss";
@import "./2020/scss/_misc.scss";

@import "./sass/layout.scss";

body {
  background: $bgColor;
  color: $textColor;
  font-family: 'Tofino', sans-serif;
  margin: 0;
  padding: 0;
}

.loader-image {
  width: 30px;
}

.text-underline {
  text-decoration: underline;
}

// Styles below moved from index.css
.text-crossed {
  text-decoration: line-through;
}

.text-rotate-90 {
  transform: rotate(90deg);
}

#content {
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: -50%;
    transform: translateX(50%);
  }
}

@media (min-width: 991px) {
  .card-back-btn {
    font-size: 24px;
    margin-left: -20px;
    margin-top: 22px;
  }

  .page-back-btn-overlay {
    cursor: pointer;
    padding: 0 15px 0 30px;
    display: inline;
  }

  .page-back-btn {
    font-size: 28px;
    margin-left: -36px;
    margin-top: 10px;
  }
}

@media (max-width: 990px) {
  .card-back-btn {
    font-size: 32px;
    margin-top: 20px;
  }

  .col-xs-pb-20 {
    padding-bottom: 20px;
  }

  .page-back-btn {
    font-size: 32px;
    margin-left: 50%;
  }

  .search-lg.rbt {
    min-width: 250px !important;
  }
}

.card-header-custom {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.675rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

a.none {
  color: inherit;
  text-decoration: none;
}

i {
  vertical-align: middle;
}

.switch {
  font-size: 1rem;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    & + label {
      position: relative;
      min-width: calc(calc(2.375rem * .8) * 2);
      border-radius: calc(2.375rem * .8);
      height: calc(2.375rem * .8);
      line-height: calc(2.375rem * .8);
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 2px;
        width: calc(calc(2.375rem * .8) * 2);
        bottom: 0;
        display: block;
      }
    }
  }

  .left {
    position: relative;
    border-radius: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    margin-bottom: 0.5rem;
    margin-right: 6px;
    height: calc(1.9375rem * .8);
    line-height: calc(1.9375rem * .8);
  }
}

// Not checked state
.switch input + label::before {
  right: 0;
  background: #333333 url('/assets/img/cross.png') no-repeat 31px center;
  border-radius: calc(2.375rem * .8);
  transition: 0.2s all;
}

.switch input + label::after {
  top: 0;
  left: 2px;
  width: calc(calc(2.375rem * .8) - calc(2px * 2));
  height: calc(calc(2.375rem * .8) - calc(2px * 2));
  border-radius: 50%;
  background-color: white;
  transition: 0.2s all;
  box-shadow: 0 0 4px 1px #6c757d
}

/* checked state */
.switch input:checked + label::before {
  background: #3745FF url('/assets/img/check.png') no-repeat 8px center;
}

.switch input:checked + label::after {
  margin-left: calc(2.375rem * .8);
}

.switch input:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25);
}

.switch input:disabled + label {
  color: #868e96;
  cursor: not-allowed;
}

/* disabled state */
.switch input:disabled + label::before {
  background: #e9ecef url('/assets/img/cross.png') no-repeat 35px center;
}

.switch.switch-sm {
  font-size: 0.875rem;

  input + label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(1.9375rem * .8);
    line-height: calc(1.9375rem * .8);
    text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem);

    &::before {
      width: calc(calc(1.9375rem * .8) * 2);
    }

    &::after {
      width: 25px;
      height: 25px;
    }
  }

  input:checked + label::after {
    margin-left: calc(1.9375rem * .8);
  }
}

.switch.switch-lg {
  font-size: 1.25rem;

  input + label {
    min-width: calc(calc(3rem * .8) * 2);
    height: calc(3rem * .8);
    line-height: calc(3rem * .8);
    text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);

    &::before {
      width: calc(calc(3rem * .8) * 2);
    }

    &::after {
      width: calc(calc(3rem * .8) - calc(2px * 2));
      height: calc(calc(3rem * .8) - calc(2px * 2));
    }
  }
}

.switch.switch-lg input:checked + label::after {
  margin-left: calc(3rem * .8);
}

.switch + .switch {
  margin-left: 1rem;
}

/* not checked state */
.switch.switch-choice input + label::before {
  background: #3745FF url('/assets/img/switch-arrow-right.png') no-repeat 32px center;
}

/* checked state */
.switch.switch-choice input:checked + label::before {
  background: #3745FF url('/assets/img/switch-arrow-left.png') no-repeat 8px center;
}

.page-loader, .page-loader-c {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.loader-circle {
  display: grid;
  align-content: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: -45px;
  margin-top: -45px;
  background: rgba(248, 249, 250, 0.5);
}

.loader-circle img {
  max-width: 106px;
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  margin-left: -45px;
  margin-top: -45px;
  overflow: hidden;
  transform-origin: 40px 40px;
  animation: rotate 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px $primaryColor;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.drop-zone {
  color: #ccc;
  border: dashed 2px #ccc;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;

  &.active {
    color: #28a745;
    border: dashed 2px #28a745;
  }

  &.reject {
    color: #dc3545;
    border: dashed 2px #dc3545;
  }

  & > .container {
    margin: 15px 0 15px 0;
  }

  & i {
    display: block;
    padding: 15px 0 15px 0;
  }
}

.fa-layers-text {
  font-size: 14px;
  padding-bottom: 5px;
}

.select__multi-value__remove:hover {
  background-color: rgb(220, 220, 220) !important;
  color: #dc3545 !important;
}

button[valid="false"] {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.table tr:hover .on-table-hover,
.table .selected .on-table-hover {
  transition: filter .5s ease-in-out, color .5s ease-in-out;
  filter: grayscale(0%);
  color: rgb(0, 0, 0);
}

.on-table-hover {
  transition: filter .5s ease-in-out, color .5s ease-in-out;
  filter: grayscale(100%);
  color: rgba(0, 0, 0, 0.42);
}

.table th.middle, .table td.middle {
  vertical-align: middle !important;
}

.container-sm {
  max-width: 600px !important;
}

input.rbt-input-hint {
  width: 100%;
}

mark.rbt-highlight-text {
  padding: initial;
  background-color: transparent;
  font-weight: 600;
}

.search-lg.rbt {
  min-width: 650px;
}

.rbt-menu {
  max-height: 405px !important;
}

a.no-ref {
  color: #212529;

  &:hover {
    text-decoration: none;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f3f3f7 !important;
  color: black !important;
}

.link {
  font-weight: bold;
  cursor: pointer;
}

.tooltip-link {
  color: $magenta;
}

.tooltip-more {
  color: $magenta;
}

.tooltip-inner {
  background: #333;
}

.bs-tooltip-bottom .arrow:before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #333;
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #333;
}

.bs-tooltip-left .arrow:before,
.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #333;
}

.bs-tooltip-right .arrow:before,
.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #333;
}
