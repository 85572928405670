@import '~foxdeli-shared/scss/colors';

.copyNote {
  position: absolute;
  left: 0;
  right: 0;
  color: $magenta;
  font-size: 0.875rem;
  display: block;

  &.blink {
    animation-name: blink;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
