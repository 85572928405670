.fd-accordion {
border-radius: 5px;

  .title {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;

    padding: 10px 20px;
    cursor: pointer;

    .flex-grow-1 {
      padding-right: 10px;
    }

    .icon {
      text-align: right;
    }
  }

  .row-element {
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .d-flex;

    h5 {
      @extend .mb-0;
      color: $primaryText;
      font-size: 16px;
    }
  }

  .body {
    padding: 20px;

    h5 {
      @extend .mt-0;
      @extend .mb-3;
      color: $primaryText;
      font-size: 16px;
    }

    strong {
      color: $primaryText;
      font-size: 14px;
    }
  }
}

.fd-accordion.light {
  background-color: #FFF;
  box-shadow: 0px 0px 5px 0px rgba(197, 197, 197, 0.75);
}

.fd-accordion.dark {
  background-color: #e9e9e9;
}

.extraservice {
  color: $textColor;
}
