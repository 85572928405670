.package {
  // margin-bottom: 20px;

  :global .form-control {
    &.is-valid,
    &.is-invalid {
      padding-right: 0.25rem;
    }
  }
}

.packageButtons {
  position: absolute;
  margin-top: 44px;
  right: -80px;

  @media (max-width: 990px) {
    position: initial;
    right: auto;
    margin-top: 0;
    width: 100%;
  }
}

.packageInfo {
  position: absolute;
  margin-top: 40px;
  left: -100px;

  @media (max-width: 990px) {
    position: initial;
    width: 100%;
  }
}
