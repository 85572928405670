.pagination {
  margin-top: 20px;
  justify-content: center;

  .page-item {

    .page-link {
      color:$primaryText;
      font-weight: 300;
    }

    &.active {
      .page-link {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #ffffff;
      }
    }
  }
}
