@import 'src/sass/_variables';
@import '~foxdeli-shared/scss/colors';

.banner {
  width: 100%;
  border-radius: 8px;
  padding-right: 40px;

  .title {
    color: $grey-black;
  }
  .description {
    color: $grey-alt;
  }
  .switchSection {
    text-align: right;
    padding-left: 15px;
    white-space: nowrap;

    .label {
      color: $grey-black;
      line-height: 25px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.active {
  background: #F0F9E8;
  border: 1px solid #B2E289;
}
.inactive {
  background: #E4E6FE;
  border: 1px solid #B6BCFE
}

.imgSizeSm {
  width: 66px;
  height: 72px;
}
.imgSizeLg {
  width: 148px;
  height: 164px;
}