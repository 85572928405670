@import '~foxdeli-shared/scss/colors';

$card-spacer-x: 1.25rem;
$card-spacer-y: 1.25rem;

.card {
  text-align: center;
  font-weight: 500;
  color: $grey-alt;

  // Financial amounts should never wrap no matter what
  white-space: nowrap;

  :global div.card-header {
    padding: $card-spacer-y $card-spacer-x 0;
  }

  :global div.card-body {
    padding: $card-spacer-x $card-spacer-y;
  }

  .progress {
    padding-bottom: 26px;
  }

  :global div.card-footer {
    padding: $card-spacer-x $card-spacer-y;
    border-top: 1px solid rgba($grey, 0.1);

    .number {
      margin-bottom: 0;
    }
  }
}

.number {
  font-size: 2rem;
  color: $grey;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.cell {
  width: 50%;
  border-right: 1px solid rgba($grey, 0.1);
  padding: 0 $card-spacer-x; // match .card padding

  .progress {
    background: #FCFCFD; // TODO: check if this solid hex bg matches `rgba(#ffffff, 0.8);` on body bg
    padding-right: $card-spacer-x;
    margin-right: calc(#{-$card-spacer-x} - 1px);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child{
    border-right: none;
    padding-right: 0;

    .progress {
      background: transparent;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.doughnutLabel {
  position: absolute;
  top: calc(1.25rem + 50px);
  left: 50%;
  transform: translateX(-50%);

  .number {
    margin-bottom: 0;
  }
}

.doughnutRange {
  bottom: $card-spacer-y;
  font-size: 0.75rem;
  justify-content: center;
  position: absolute;
}

.doughnutRangeMin {
  left: calc(50% - var(--doughnutWidth) / 2);
  transform: translateX(-100%);
}

.doughnutRangeMax {
  left: calc(50% + var(--doughnutWidth) / 2);
}

.legend {
  list-style-type: none;

  .legendItem {
    font-size: 0.75rem;

    &::before {
      $size: 1rem;

      background-color: var(--legendItemColor, $grey-50);
      border-radius: 4px;
      content: '';
      display: block;
      height: $size;
      margin-right: 0.5rem;
      width: $size;
    }
  }
}

.returnsPopover {
  min-width: 250px;

  :global .popover-body {
    padding: 1rem;
  }

  :global .progress-list-value,
  :global .progress-list-label {
    width: 50%;
  }
}
