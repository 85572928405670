:global .icon {
  font-size: 1.25rem;
}

.iconDropdown {
  margin-left: 2rem;
}

.datePickerButton {
  // Overrides date-fns month format for "cs" locale ("listopad" => "Listopad")
  // TODO: Override using a global util (formatDate()) or remove completely
  text-transform: capitalize;

  &.isOpen {
    .iconDropdown {
      transform: rotateX(180deg);
    }
  }
}
