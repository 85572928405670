.packageTemplate {
  @media (max-width: 990px) {
    margin-top: 10px;
    text-align: center;
  }
}

.actions {
  position: absolute;
  left: -100px;
  margin-top: 7px;
}
