.newDelivery {
  :global {
    .form-group.append-form-group {
      margin-bottom: 50px;

      .invalid-feedback {
        position: absolute;
        top: 40px;
      }
    }
  }
}
