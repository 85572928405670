.simple-modal {
  &.modal-fullscreen {
    position: absolute;
    top: 0;
    left: 5vw;

    .modal-header {
      padding: 1.5rem;
    }

    .modal-content {
      width: 90vw;
      height: 90vh;
    }

    .modal-body {
      padding: 1.5rem;
      width: 100%;
      height: 75%;
    }
  }
}