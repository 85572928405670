.extraService {
  margin-bottom: 12px;

  label {
    font-size: 14px;
    padding-top: 1px;
    color: #656565;
  }

  .hasDescription {
    padding-right: 5px;
    display: inline-block;
  }
}
