@import '~foxdeli-shared/scss/colors';
@import 'src/sass/variables';

.simple-dropdown {
  position: relative;
  display: inline-block;
  margin: -0.5rem;

  &-button {
    color: $grey-alt;
    padding: 0.5rem;
    font-size: .8rem;
    border: none;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }

  &-content {
    display: none;
    position: absolute;
    right: 0;
    font-weight: bold;
    font-size: 0.9375rem;
    padding: 0.5rem;
    background-color: $bgCardColor;
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 10%);
    border-radius: 8px;
    z-index: 1;

    .simple-dropdown-link {
      color: $grey-alt;
      padding: 0.3rem 0.5rem;
      text-decoration: none;
      display: block;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        text-decoration: none;
        color: $magenta;
      }
    }
  }

  &:hover .simple-dropdown-content {
    display: block;
  }

  &:hover .simple-dropdown-button {
    color: $magenta;
  }
}
