@import '~foxdeli-shared/scss/colors';

/***********  Colours ************/
$bgColor: #F3F3F7;
$bgCardColor: #FCFCFD;
$white: #FFFFFF;
$black: #333333;

$navColor: #FFFFFF;

$primaryText: #1B2E34;
$textColor: #949495;
$textColorNotActive: #849196;
$textMuted: $textColor;

$loaderColor: #e2e3e5;

$primaryColor: $magenta;
$secondaryColor: $magenta;
$secondaryColorDarker: #59af10;
$warningColor: $yellow;

/**  Left menu **/
$menuTextColor: $textColorNotActive;
$menuTextColorActive: $black;

$submenuTextColor: #68817D;
$submenuTextColorActive: $textColor;

$inputBorder: #e9ecef;
$inputAddon: #e9ecef;

$danger: #e42f2f;
