.btnPackageTemplate {
  background: rgb(230, 232, 234);
  border: 1px solid #dcdcdd;
  width: 118px;
  height: 70px;
  font-size: 14px;
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 8px;
  padding: 14px 22px 13px 22px;

  &:hover,
  &:focus,
  &.dropdown-toggle {
    background-color: darken(rgb(230, 232, 234), 5%);
  }
}
