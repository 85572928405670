@import '~foxdeli-shared/scss/colors';

.inputGroup {
  display: flex;
  width: 100%;

  input {
    border-radius: 4px 0 0 4px;
  }
}

.inputButton {
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 0 10px;
  background: #a1a1a1;
  color: white;
}

