@import '~foxdeli-shared/scss/colors';

.closeButton {
  background: none;
  border: none;
  box-shadow: none;
  outline: transparent;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 1;

  &:focus {
    outline: transparent;
  }
}

.title {
  color: $grey;
  font-size: 1.625rem;
  font-weight: 700;
}

.userEmail {
  color: $grey;
}

.emailType {
  :global {
    .select__placeholder {
      color: #949495 !important;
    }
  }
}