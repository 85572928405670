@mixin btn($background, $border, $hover-background, $hover-border, $active-background, $active-border, $color, $hover-color: $color) {
  @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

  color: $color;

  &:hover {
    color: $hover-color;
  }
}

@mixin btn-link($color, $hover-color) {
  @extend .btn-link;
  padding: 0.25rem;
  color: $color;

  &:hover {
    color: $hover-color;
  }

  &:disabled,
  &.disabled {
    color: $grey-50;
  }
}

@include ds {
  .btn {
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .btn-sm {
    padding: 0.5rem 1rem;
  }

  .btn-lg {
    padding: 1rem 2.5rem;
  }

  .btn-primary {
    @include btn($blue, transparent, $blue, transparent, #3642C6, #3642C6, $white); // TODO: #3642C6 - new colour palette colour incoming

    &:hover {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), $blue;
      box-shadow: 0px 10px 20px rgba(55, 69, 255, 0.2);
    }
  }

  .btn-secondary {
    @include btn($blue-10, $blue-10, $blue-10, $blue, $blue-10, $blue, $blue);
  }

  .btn-light {
    @include btn($grey-soft, $grey-light, $white, $grey-light, $white, $grey-light, $grey);

    &:hover {
      box-shadow: 0px 10px 20px rgba(52, 58, 64, 0.08);
    }
  }

  .btn-light-alt {
    @include btn($white, $grey-light, $grey-soft, $grey-light, $blue-10, $grey-light, $grey-alt, $blue);

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > .btn-light-alt.dropdown-toggle {
      color: $blue;
    }

    // TODO: should other buttons have :focus defined too?
    &:focus {
      border: 1px solid $blue;
    }
  }

  .btn-link-primary {
    @include btn-link($blue, $blue-70);
  }

  .btn-link-secondary {
    @include btn-link($grey-alt, $grey);
  }

  .btn-icon {
    align-items: center;
    display: inline-flex;
    padding: 0.75rem 0.9375rem;
  }

  .popover {
    margin-top: 0.25rem;
  }
}
