@import "~foxdeli-shared/scss/colors";
@import 'src/sass/variables';

$border-radius: 8px;
$padding: 40px;
$spacing-x: 24px;

.banner {
  align-items: center;
  background: $bgCardColor;
  border-radius: $border-radius;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding: $padding;
  position: relative;

  @media (max-width: 990px) {
    flex-flow: column nowrap;
    justify-content: center;
  }

  .image {
    border-radius: $border-radius * 0.5;
    max-height: 112px;
    width: auto;
  }

  .contentContainer {
    flex-grow: 2;
    padding: 0 $spacing-x;

    @media (max-width: 990px) {
      padding: $spacing-x 0;
    }
  }

  .content {
    max-width: 530px;

    @media (max-width: 990px) {
      text-align: center;
    }
  }

  .title {
    color: $grey;
    font-size: 17px;
    font-weight: 700;
  }

  .actions {
    align-self: flex-end;
  }

  .closeButton {
    color: $grey;
    position: absolute;
    right: $padding * 0.35;
    top: $padding * 0.35;
  }
}
