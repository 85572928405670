.company-information-banner {
  display: flex;
  align-items: center;
  position: relative;
  align-content: center;
  justify-content: space-between;

  &-text, &-image, &-link {
    padding: .5rem;
  }

  &-image {
    img {
      max-width: 102px;
      min-width: 80px;
      width: 100%;
      height: auto;
    }
  }

  &-text {
  }

  &-link a {
    padding: 9px 12px 8px 12px;
  }
}