.card.timeline {
  .card-header {
    .right {
        font-size: 14px;

      .custom-control {
        margin-right: 30px;
        color: #666;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .timeline-accordion-wrapper {

    .timeline-accordion-icon-wrapper {
      .foxdeli-icon {
        position: relative;
        left: -4px;
        width: 16px;

        &.warning {
          left: -7px;
        }
      }
    }

    &:last-of-type {
      .timeline-accordion-icon-wrapper {
        @extend .justify-content-start;

        span.dots {
          display: none;
        }
      }
    }
  }

  .timeline-accordion-icon-wrapper {
    margin-right: 12px;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    position: relative;
    top: 10px;

    svg {
      margin: 4px 0;
    }

    .dots {
      @extend .flex-grow-1;

      width: 1px;
      margin: auto;
      // border-image-source: url(/assets/images/dots-background.svg);
      border-style: dotted;
      border-image-repeat: round;
      border-image-slice: 100 100;
      border-width: 0 0 0 3px;
      border-color: #c7c7ca;
    }

    &.trackAndTrace {
      color: #666;
    }
    &.communication {
      color: $primaryColor;
    }
    &.note {
      color: $secondaryColor;
    }
  }

  .more-button {
    @extend .text-center;

    position: relative;

    .backgroundGradient {
      padding: 20px;
    }

    &.showBg {
      margin-top: -40px;

      .backgroundGradient {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(252,252,253,1) 50%);
      }
    }
  }

  .card-body {
    form.add-note {
      @extend .d-flex;
      @extend .mb-3;

      background-color: $bgColor;
      border-radius: 25px;

      .input {
        @extend .flex-grow-1;
        @extend .d-flex;
        @extend .align-items-center;

        svg {
          @extend .mx-3;
          color: $primaryColor;
        }

        .form-control {
          @extend .flex-grow-1;

          background-color: transparent;
          border: 0;
        }

      }

      button {
        padding: 10px 30px;
      }

    }

    .fd-accordion.dark {
      @extend .mb-2;

      border-radius: 8px;
      background-color: $bgColor;

      .title {
        padding-top: 10px;
        padding-left: 25px;

        h4 {
          @extend .my-0;

          line-height: 1em;
          font-size: 18px;
        }
      }

      .subtitle {
        font-size: 14px;
        margin-top: 6px;

        h5 {
          font-size: 16px;
          color: $primaryText;
        }

        span, a {
          margin-right: 6px;
        }

        .link-button {
          cursor: pointer;
          color: $textColor;

          &:hover {
            text-decoration: underline;
            color: $primaryColor;
          }
        }
      }

      .body {
        padding-top: 0;

        .event {
          @extend .mb-3;
          @extend .ml-3;

          h5 {
            font-size: 16px;
            color: #666666;
            margin-bottom: 0;
          }
          .subtitle {
            margin-top: 0;
          }
        }
      }

      &.note {
        .title {
          h4 {
            color: $secondaryColor;
          }
        }
      }

      &.communication {
        .title {
          h4 {
            color: $primaryColor;
          }
        }
      }

    }

  }
}


ul.timeline {
  list-style-type: none;
  position: relative;

  &:before {
    content: ' ';
    background: $loaderColor;
    display: inline-block;
    position: absolute;
    left: 15px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }

  & > li {
    margin: 20px 0;
  }

  & > li:before {
    content: ' ';
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    background: $primaryColor;
    left: 10px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
    z-index: 400;
  }

  & > li small {
      padding-left: 10px;
  }

  .alert {
    border-radius: 8px;
    box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.02);

    &-success {
      color: $white;
      background-color: $primaryColor;
      border-color: transparent;
    }
  }

  .button {
    position: absolute;
    bottom: 20px;
    margin: 0 auto;
    left: 20px;
    right: 0;
  }

  .d-opacity {
    opacity: 0.2;
  }
}
