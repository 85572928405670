@import './src/sass/_variables';

.sidebar {
  background: $white;
  text-align: left;
  min-width: 250px;
  max-width: 250px;
  color: #000;
  transition: all 0.3s;
  padding: 20px 0 20px 20px;
  top: 0;
  bottom: 0;
  position: fixed;

  &.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
  }
}

.header {
  margin: 0 auto;
  padding-right: 45px;
}

.navbarNav {
  padding-left: 30px;
  list-style: none;
  margin-bottom: 0;

  &.topNavbar {
    padding-top: 25px;
  }

  &.bottomNavbar {
    padding-top: 0;
  }

  &.quickAccess {
    padding-top: 0;
  }
}

.hr {
  border: 0;
  clear:both;
  display:block;
  width: 90%;
  padding-left: 10px;
  background-color: $menuTextColor;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 30px;
}

.version {
  color: #949495;
  font-size: 12px;
  text-align: center;
}

.logo {
  width: 113px;
}
/* Narrow sidebar style */
.sidebarNarrow {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  margin-left: 0 !important;
  padding: 0 .5rem;

  .navbarNav {
    padding-left: 0;
  }

  .hr {
    width: 90%;
    margin: 0 auto;
  }

  .logo {
    margin: 20px 0 0 3px;
    overflow:hidden;
    width: 32px;

    img {
      display: block;
    }
  }

  .header {
    padding-right: 0;
  }

  .languageSwitcher {
    :global .select {
      &__menu {
        box-shadow: 0 15px 30px rgb(52 58 64 / 10%);
      }

      &__menu-list {
        padding: 2px 2px 0;
      }

      &__option {
        margin-bottom: 2px;
        transform: none;
        font-size: 0.7525rem;
        padding: 8px 6px;
        border-radius: 0;
      }

      &__value-container {
        padding: 2px 2px;
        flex-wrap: nowrap;
      }

      &__indicator {
        padding: 4px;
      }
    }
  }
}
