/*
    :: Datatable checkbox
 */
.datatable-checkbox {
  padding-left: initial;

  label {
    cursor: pointer;
  }
}

.datatable-checkbox .custom-control-label::before {
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  border-radius: 1.25rem;
}

.datatable-checkbox label::before {
  width: 1.8rem;
  height: 1.8rem;
  left: -0.9rem;
}

.datatable-checkbox label::after {
  width: 1.8rem;
  height: 1.8rem;
  left: -0.9rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primaryColor;
  border-color: $white;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $primaryColor;
  border-color: $white;
}

.datatable-checkbox {
  input:disabled ~ label {
    cursor: default;
  }
  input:disabled ~ .custom-control-label::before {
    background-color: #e3e3e3;
  }
}

.custom-control-label::before {
  background-color: $white;
  border: 1px solid $primaryColor;
  box-shadow: 0 4px 11px 3px rgba(0, 0, 0, 0.05);
}

.table .selectedRow .on-table-hover {
  transition: filter .5s ease-in-out, color .5s ease-in-out;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  color: rgb(0, 0, 0);
}

#error-table {
  table {
    font-size: 13px;
    th {
      padding: 12px;
      vertical-align: middle;
      white-space: nowrap;
    }
    td {
      vertical-align: middle;
    }
  }
}
