@import "./topbar.scss";

/* Components */
@import "./alert.scss";
@import "./accordion.scss";
@import "./background.scss";
@import "./forms.scss";
@import "./pagination.scss";
@import "./card.scss";
@import "./tables.scss";
@import "./dataTable.scss";
@import "./datepicker.scss";
@import "./icons.scss";
@import "./loader.scss";
@import "./searchBar.scss";
@import "./timeline.scss";
@import "./modal.scss";
@import "./dropzone.scss";
@import "./badges.scss";
@import "./buttons.scss";
@import "./tooltip.scss";
@import "./notifications.scss";
@import "./collectionPlaces.scss";

/* Pages */
@import "./deliveries.scss";
@import "./contacts.scss";
@import './protocols.scss';
@import "./batches.scss";
@import "./notification-center.scss";
@import "./settings.scss";
@import "./agents.scss";

#content {
  z-index: 1;
  display: block;
}

h3 {
  text-transform: uppercase;
  font-size: 20px;
  color: $primaryText;
  margin-top: 10px;
  margin-bottom: 5px;
}

h4 {
  font-size: 20px;
  color: $primaryText;
  margin-top: 10px;
  margin-bottom: 5px;
}

a {
  color: $magenta;
  &:hover {
    color: darken($magenta, 20%);
    text-decoration: none;
  }
}

.text-muted {
  color: $textColorNotActive !important;
}

.text-transform-none {
  text-transform: none;
}

.dropdown {
  outline: none;
}

.input-group > .input-group-append > .btn:last-child,
.input-group > .input-group-append > .input-group-text:last-child {
  background: transparent;
  box-shadow: none;
  color: $primaryText;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover, &:focus, &.dropdown-toggle  {
    box-shadow: none;
  }
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input-appended {
  .form-control {
    border-right: none;
  }
  .input-group-text {
    background: $white !important;
    border: 1px solid #e9ecef;
    color: darken($white, 20%) !important;
  }
}

/* ---------------------------------------------------
    DELIVERIES > PACKAGES > ACTIONS
----------------------------------------------------- */
.navbar-nav {
  .disabled {
    color: #666;
    .fa-layers-counter {
      background-color: #bf1929;
    }
  }
}

.horizontally-center-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
  }

.initialsIcon {
  background-color: #D8D8D8;
  border-radius: 50px;
  padding: 8px 9px;
  color: white;
}

.settings-sticky-card {
  @extend .sticky-top;

  top: 150px;
}

.headline {
  a {
    position: relative;
    width: 100%;
  }
}

.logged {
  &--in {
    margin-left: 80px; // 250px is be the original value - use it when you want revert full size menu
  }

  &--out {
    margin-left: 0;
  }

}

dl {
  dt:last-child,
  dd:last-child {
    margin-bottom: 0;
  }
}

.mnh-100 {
  min-height: 100%;
}

.navbar-light {
  z-index: 2;
}

/* ---------------------------------------------------
    LAYOUT BREAKPOINTS OVERRIDE
----------------------------------------------------- */
// deliveries
@media (max-width: 990px) {
  #content.logged--in {
    margin-left: 80px !important;

    .fixed-top{
      min-width: auto;
      margin-left: 80px;
    }

    .content-wrapper {
      margin-top: 190px;
    }
  }

  .navbar-light {
    .container{
      max-width: 100%;
    }
  }

  .settings-left-block-menu-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    align-content: stretch;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

@media (max-width: 576px) {
  #content.logged--in {
    min-width: auto;

    .fixed-top{
      min-width: 90%;
    }
  }
}

@media (min-width: 576px) {
  .deliveries {
    .container-sm, .container {
      max-width: 90%;
    }
  }
}

// global styles
@media (max-width: 576px) {
  .container-sm, .container {
    max-width: 90%;
  }
}

@media (min-width: 1400px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 90%;
  }
}

@media (min-width: 2990px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 70%
  }
}

// container reset
.card {
  .container {
    max-width: 100%;
  }
}