@import '~foxdeli-shared/scss/colors';

@include legacy {
  .btn-group {
    &.show > .btn-primary.dropdown-toggle {
      background-color: $secondaryColor;
      color: $white;
      &:hover, &:focus, &.dropdown-toggle  {
        color: $secondaryColor !important;
        border-color: darken($secondaryColor, 10%);
        background-color: $white;
      }
    }
  }

  .btn {
    border: 0 none;
    border-radius: 100px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Tofino, sans-serif;
    text-align: center;
    letter-spacing: 0.01em;
    white-space: nowrap;
    padding: 14px 22px 13px 22px;
    line-height: 18px;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: darken($secondaryColor, 5%);
    border-color: darken($secondaryColor, 5%);
    border-width: 1px;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: $primaryColor !important;
    background-color: darken($white, 5%);
    border: 1px solid $primaryColor;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: $secondaryColor !important;
    background-color: darken($white, 5%);
    border-color: darken($secondaryColor, 5%);
    border-width: 1px;
  }

  .btn-warning:not(:disabled):not(.disabled):active,
  .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff !important;
    background-color: darken($warningColor, 5%);
    border-color: darken($warningColor, 5%);
    border-width: 1px;
  }

  .btn-primary, .btn-primary.dropdown-toggle{
    font-weight: 700 !important;
    color: #fff;
    border-color: transparent;
    background-image: linear-gradient(110.87deg,#ff5b00,#ff5acc 50%,#ff5b00);
    background-size: 200% 100%;
    box-shadow: 0 0 20px transparent;

    &:hover, &:focus {
      color: #fff;
      border-color: transparent;
      background-position: 100% 0;
      filter: drop-shadow(1px 2px 4px rgba(255, 90, 204, 0.8))
    }

    &.disabled,&:disabled {
      background-color: darken($primaryColor, 10%);
      border-color: darken($primaryColor, 10%);
      color: #ffffff !important;
      &:hover{
        background-color: darken($primaryColor, 5%);
        color: #ffffff !important;
        cursor: not-allowed;
      }
    }
  }
  .btn-primary.dropdown-toggle:hover:active:focus,
  .btn-primary.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem rgba(231, 29, 115, 0.5);
  }

  .btn-secondary, .btn-secondary.dropdown-toggle{
    font-weight: 400;
    background-color: $white;
    color: $primaryColor;
    box-shadow: 0 3px 12px rgba(0,0,0,0.1);
    border: 1px solid $white;
    &:hover, &:focus {
      border: 1px solid $primaryColor;
      background-color: $white;
      color: $primaryColor !important;
    }
    &.disabled,&:disabled {
      background-color: darken($white,8%) !important;
      color: darken($white,40%) !important;
      border-color: darken($white,30%) !important;
      &:hover{
        cursor: not-allowed;
        background-color: darken($white,5%) !important;
      }
    }
  }

  .btn-secondary.dropdown-toggle:hover:active:focus,
  .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 29, 115, 0.5);
  }

  .btn-warning, .btn-warning.dropdown-toggle {
    color: #fff !important;
    font-weight: 700 !important;
    background-color: $warningColor;
    box-shadow: 0 5px 12px rgba(233, 177, 24, 0.35);
    border: 1px solid $warningColor;

    &:hover, &:focus {
      color: darken($warningColor, 5%) !important;
      border: 1px solid $warningColor;
      background-color: $white;
    }

    &.disabled,&:disabled {
      background-color: darken($warningColor, 10%);
      border-color: darken($warningColor, 10%);
      color: lighten($warningColor,10%) !important;

      &:hover{
        background-color: darken($warningColor, 5%);
        color: lighten($warningColor,20%) !important;
        cursor: not-allowed;
      }
    }
  }
  .btn-warning.dropdown-toggle:hover:active:focus,
  .btn-warning.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem rgba(233, 177, 24, 0.5);
  }

  .btn-primary, .btn-secondary, .btn-warning {
    &:active{
      box-shadow: transparent;
    }
  }

  .btn-link {
    color: $primaryColor;
    &:hover, &:focus, &.dropdown-toggle  {
      background-color: $white;
      text-decoration: none;
      color: darken($primaryColor, 30%);
      box-shadow: 0 3px 12px rgba(0,0,0,0.1);
    }
  }

  a, button, span {
    &.btn-outline-primary {
      color: $primaryColor;
      border: 1px solid $primaryColor;
      background-color: $white;
      &:hover, &:focus, &.dropdown-toggle  {
        background-color: $white;
        text-decoration: none;
        color: darken($primaryColor, 10%) !important;
        box-shadow: 0 7px 11px 2px rgba(0,0,0,0.04);
        border: 1px solid darken($primaryColor, 10%);
      }
    }

    &.btn-outline-secondary {
      color: $primaryColor;
      border: 1px solid $primaryColor;
      background-color: $white;
      &:hover, &:focus, &.dropdown-toggle  {
        background-color: $white;
        text-decoration: none;
        color: darken($primaryColor, 10%) !important;
        box-shadow: 0 7px 11px 2px rgba(0,0,0,0.04);
        border: 1px solid darken($primaryColor, 10%);
      }
      &.disabled {
        background: transparent;
        &:hover {
          color: #333;
        }
      }
    }

    &.btn-outline-warning {
      color: darken($warningColor, 5%);
      border: 1px solid $warningColor;
      background-color: $white;
      &:hover, &:focus, &.dropdown-toggle  {
        background-color: $white;
        text-decoration: none;
        color: darken($warningColor, 15%) !important;
        box-shadow: 0 7px 11px 2px rgba(0,0,0,0.04);
        border: 1px solid darken($warningColor, 10%);
      }
    }

    &.btn-outline-danger {
      color: darken($danger, 5%);
      border: 1px solid $danger;
      background-color: $white;
      &:hover, &:focus, &.dropdown-toggle  {
        background-color: $white;
        text-decoration: none;
        color: darken($danger, 15%) !important;
        box-shadow: 0 7px 11px 2px rgba(0,0,0,0.04);
        border: 1px solid darken($danger, 10%);
      }
    }
  }


  button, a {
    &.btn, &.dropdown-item {
      font-size: 14px;
      font-weight: 400;
      svg {
        margin-right: 6px;
        &.no-margin {
          margin-right: 0;
        }
      }
    }
  }

  .btn-link {
    @extend .btn-outline-primary;
  }

  .btn-link-action {
    text-transform: none !important;
    color: $primaryColor;
    background: transparent;
    line-height: normal;
    text-align: unset !important;
    vertical-align: unset !important;
    font-size: unset !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    padding: 0;
    &:hover {
      cursor: pointer;
      color: $primaryColor !important;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: 300;
  }

  .btn-group.full-width {
    display: flex;
    .btn {
      flex: 1
    }
  }

  .btn-switcher {
    background-color: $textColorNotActive;
    color: darken($white, 10%);
    box-shadow: 0 7px 11px 2px rgba(0,0,0,0.04);
    border-radius: 4px;
    padding: 0.44rem 1.5rem;
    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover, &:focus, &.dropdown-toggle  {
      background-color: $textColorNotActive;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $white;
      background-color: $primaryColor;
    }
  }

  .btn-flat-blue {
    width: 100%;
    border-radius: 8px;
    background-color: $blue-10;
    color: $blue;
    border: 1px solid $blue-10;
    box-shadow: none;

    &:active,
    &:hover,
    &:focus {
      background-color: $blue-10 !important;
      border: 1px solid $blue-50;
      color: $blue !important;
    }
  }

  .custom-input {
    img {
      align-items: center;
      display: flex;
      position: absolute;
      max-width: 100%;
      max-height: 32px;
      margin-left: 6px;
      margin-top: 4px;
      z-index: 3;
    }

    label {
      border: 1px solid #ced4da;
      padding-left: 45px;
      padding-top: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      &:after {
        display: none;
      }

      &.btn {
        padding-top: 13px;
      }
    }
  }

  .custom-input-color {
    .preview {
      border: 1px solid rgb(222, 226, 230);
      right: 0;
      align-items: center;
      display: flex;
      position: absolute;
      width: 28px;
      height: 28px;
      margin-right: 25px;
      margin-top: 5px;
      z-index: 3;
    }

    .picker {
      position: absolute;
      z-index: 2
    }

    .cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
