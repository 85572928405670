@mixin legacy {
  ._legacy,
  .modal {
    @content;
  }
}

@mixin ds {
  ._ds {
    @content;
  }
}
