@import './src/sass/_variables.scss';
@import '~foxdeli-shared/scss/colors';

.nav-item-sidebar {
  margin-top: 3px;

  .nav-link {
    color: $menuTextColor;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    padding: 0.25rem 1rem;

    &:hover {
      color: $magenta;
      text-shadow: 0 0 1px $magenta-50;

      .nav-item-sidebar-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    &.active {
      color: $magenta;
      text-shadow: 0 0 1px $magenta-50;
    }

    .foxdeli-icon {
      font-size: 1.8em;
      position: relative;
      top: -3px;
    }
  }

  .nav-item-sidebar-tooltip {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    word-break: keep-all;
    white-space: nowrap;
    padding: 5px 8px;
    background-color: $menuTextColorActive;
    color: $white;
    text-align: center;
    border-radius: 6px;
    box-shadow: 3px 2px 4px 0 $grey-50;
    position: absolute;
    z-index: 1;
    bottom: 25%;
    left: 100%;
    margin-left: -5px;
    text-shadow: none;

    &::after {
      content: "";
      position: absolute;
      top: 32%;
      left: 0;
      margin-left: -12px;
      border-width: 6px;
      border-style: solid;
      border-color: $menuTextColorActive transparent transparent transparent;
      transform: rotate(90deg);
    }

    .nav-item-sidebar-badge {
      font-size: 9px;
      text-transform: uppercase;
      padding: 5px 5px;

      &.badge-secondary {
        background-color: $white;
      }
    }
  }
}


