.foxdeli-icon {

  font-size: 1.5em;

  &.size {
    &_default {
      font-size: 1.5em;
    }
    &_1 {
      font-size: 1em;
    }
    &_1_2 {
      font-size: 1.2em;
    }
    &_2 {
      font-size: 2em;
    }
    &_3 {
      font-size: 3em;
    }
  }

  &.warning {
    color: $secondaryColor;
  }

}

@font-face {
  font-family: 'foxdeli-icons';
  src: url('/assets/fonts/icons/foxdeli-icons.eot?62635286');
  src: url('/assets/fonts/icons/foxdeli-icons.eot?62635286#iefix') format('embedded-opentype'),
  url('/assets/fonts/icons/foxdeli-icons.woff2?62635286') format('woff2'),
  url('/assets/fonts/icons/foxdeli-icons.woff?62635286') format('woff'),
  url('/assets/fonts/icons/foxdeli-icons.ttf?62635286') format('truetype'),
  url('/assets/fonts/icons/foxdeli-icons.svg?62635286#dashboard') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dashboard';
    src: url('../font/dashboard.svg?63256825#dashboard') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "foxdeli-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-davky:before { content: '\e800'; } /* '' */
.icon-adresar:before { content: '\e801'; } /* '' */
.icon-autorizace:before { content: '\e802'; } /* '' */
.icon-api:before { content: '\e803'; } /* '' */
.icon-copy:before { content: '\e804'; } /* '' */
.icon-emaily_sms:before { content: '\e805'; } /* '' */
.icon-faktury:before { content: '\e806'; } /* '' */
.icon-informace:before { content: '\e807'; } /* '' */
.icon-kos:before { content: '\e808'; } /* '' */
.icon-licence:before { content: '\e809'; } /* '' */
.icon-logo_stitek:before { content: '\e80a'; } /* '' */
.icon-nastaveni_upozorneni:before { content: '\e80b'; } /* '' */
.icon-obnovit:before { content: '\e80c'; } /* '' */
.icon-odhlasit_se:before { content: '\e80d'; } /* '' */
.icon-nastaveni:before { content: '\e80e'; } /* '' */
.icon-pomoc_1:before { content: '\e80f'; } /* '' */
.icon-zasilky:before { content: '\e810'; } /* '' */
.icon-zpravy:before { content: '\e811'; } /* '' */
.icon-prepravci:before { content: '\e812'; } /* '' */
.icon-velikost_baliku:before { content: '\e813'; } /* '' */
.icon-uzivatele:before { content: '\e814'; } /* '' */
.icon-upravit:before { content: '\e815'; } /* '' */
.icon-svozova_mista:before { content: '\e816'; } /* '' */
.icon-statistiky:before { content: '\e817'; } /* '' */
.icon-sledovani_zasilky:before { content: '\e818'; } /* '' */
.icon-save:before { content: '\e819'; } /* '' */
.icon-profil:before { content: '\e81a'; } /* '' */
.icon-videt:before { content: '\e81b'; } /* '' */
.icon-vratky_reklamace:before { content: '\e81c'; } /* '' */
.icon-filtr:before { content: '\e81d'; } /* '' */
.icon-hodnoceni:before { content: '\e81e'; } /* '' */
.icon-komentare:before { content: '\e81f'; } /* '' */
.icon-kos_bigger:before { content: '\e820'; } /* '' */
.icon-upozorneni:before { content: '\e821'; } /* '' */
.icon-nahrat_bigger:before { content: '\e822'; } /* '' */
.icon-zrusit_bigger:before { content: '\e823'; } /* '' */
.icon-warning_bigger:before { content: '\e824'; } /* '' */
.icon-pozor_normal:before { content: '\e825'; } /* '' */
.icon-vytisknout_stitek_bigger:before { content: '\e826'; } /* '' */
.icon-protokoly:before { content: '\e827'; } /* '' */
.icon-marketingove_kampane:before { content: '\e828'; } /* '' */