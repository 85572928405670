.btnInput {
  padding: 6px 14px 6px 14px !important;
  border: 1px solid #e9ecef !important;
  border-left: 0 !important;
  line-height: 1.5rem !important;

  svg {
    margin: 0px;
    padding: 0px;
  }
}
