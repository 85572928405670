@import '~foxdeli-shared/scss/colors';
@import './src/sass/_variables.scss';

.simpleTable {
  border-collapse: separate;
  border-spacing: 0 .5rem;
  padding: 0;
  width: 100%;

  tbody tr:hover td {
    background-color: ghostwhite;
  }

  td, th {
    padding: 1rem 0.5rem;

    &:first-child {
      border-radius: 8px 0 0 8px;
      padding-left: 1rem;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
      padding-right: 1rem;
    }
  }
  th {
    color: $grey-alt;
    padding-bottom: 0;
    font-size: 0.8125rem;
    font-weight: normal;
  }
  td {
    background: $bgCardColor;
    font-size: 0.9375rem;
  }

  &.tableBoxed {
    tbody {
      tr {
        box-shadow: 0 6px 10px 2px rgb(0 0 0 / 2%);

        &:hover {
          box-shadow: 0 2px 10px 1px rgb(0 0 0 / 10%), 0 1px 0 0 $grey-lighter;
          transition: box-shadow 0.3s ease-in-out;
          border-radius: 8px;
        }
      }
    }
  }

  &.tableBordered {
    tbody tr {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
  }

  .clickableColumn {
    cursor: pointer;
  }
}