@import '~foxdeli-shared/scss/colors';

.guide {
  border: 1px solid $grey-light;
  border-radius: 4px;
  padding: 1rem;

  h3 {
    margin-top: 0;
  }

  p {
     margin-bottom: 0;
  }
}