@import '~foxdeli-shared/scss/colors';

// TODO: remove table element selector once the specificity issue is solved
table.table {
  border-spacing: 0 0.5rem;
  border-collapse: separate;
  table-layout: fixed;

  thead,
  tbody {
    th,
    td {
      &:first-child {
        position: sticky;
        left: 0;
      }
    }
  }

  thead {
    th {
      font-size: 0.8125rem;
      color: $grey-alt;
      font-weight: normal;
      border: 0;
      padding: 0 16px 4px;
      width: 150px;

      &:first-child,
      &:last-child {
        background: $grey-lighter;
      }
    }
  }

  tbody {
    th, td {
      padding: 12px 16px;
      background: $grey-softer;
      color: $grey;
      border-width: 1px 0 1px 0;
      border-color: $grey-light;
      border-style: solid;
      vertical-align: middle;
    }

    th {
      font-weight: 500;
      border-left-width: 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 2px solid $grey-light;

      img {
        margin-right: 12px;
      }
    }

    td:last-child {
      border-right-width: 1px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 2px solid $grey-light;
    }

    :global .progress {
      max-width: 80px;
    }

    :global .progress-bar.bg-info {
      background-color: $blue !important;
    }
  }
}

.icon {
  width: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.tooltipContent {
  font-size: 0.75rem;
}
