.Toastify {
  &__toast-container {
    width: 800px;

    &--top-center {
      margin-left: -400px;
    }

    .Toastify__toast {
      border-radius: 12px;
      padding: 20px;
      overflow: auto;
      cursor: unset;

      &.Toastify__toast--error {
        background: #e42f2f;

        a {
          color: $white
        }
      }
      &.Toastify__toast--success {
        background: $green;
      }
      &.Toastify__toast--info {
        background: $secondaryColor;
      }

      .Toastify__toast-body {
        @include font-tofino;

        line-height: 1.5em;
        font-size: 16px;
        font-weight: 300;

        a {
          cursor: pointer;
          text-decoration: underline;
          transition: ease 500ms;

          &:hover {
            color: darken(#fff, 30%);
          }
        }

        p {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
