@import '~foxdeli-shared/scss/colors';

.ratingBar {
  li {
    list-style: none;
    display: flex;

    .star {
      margin: 0 2px;
      display: inline-flex;
      font-size: 20px;
    }

    &.on .star {
      --color: $yellow;
      color: $yellow;
    }

    &.off .star {
      --color: $grey;
      color: $grey;
    }
  }
}

.tag,
.comment {
  font-size: 0.8125rem;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 4px;
}

.tag {
  list-style: none;
  margin-right: 8px;
  margin-top: 8px;
  white-space: nowrap;
}

.level1 {
  color: $red;
  background: $red-10;
}

.level2 {
  color: $magenta;
  background: $magenta-10;
}

.level3 {
  color: $green;
  background: $green-10;
}
