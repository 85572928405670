.mainTitle {
  color: #1D2D40;
  min-width: 800px;

  :global .action-group {
    .btn-group {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
