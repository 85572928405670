@import '/src/sass/_variables';

.badge {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  padding: 5px 8px;
}

.badge-pill {
  border-radius: 10px;
}

.badge-primary {
  background: transparentize($green, 0.9);
  color: $green;
}

.badge-secondary {
  background: transparentize($secondaryColor, 0.9);
  color: $secondaryColor;
}

.badge-muted {
  background: #F3F3F7;
  color: #6C757D;
}
