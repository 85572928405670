@import '~foxdeli-shared/scss/colors';

.button {
  position: relative;

  // !important overrides will not be needed as soon as we refactor sass
  &,
  &:focus,
  &:hover {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    color: #949495 !important;
  }

  .icon {
    font-size: 18px;
    margin-right: 0 !important;
  }

  .tag {
    color: #fff;
    background: $magenta;
    font-size: 11px;
    font-weight: 700;
    line-height: 0.9;
    min-width: 16px;
    height: 16px;
    padding: 3px 3px;
    position: absolute !important;
    right: 12px !important;
    top: 5px !important;
    border-radius: 20px;
  }
}

.input {
  max-width: 392px;
}
