.deliveries-list {
  .table-loader {
    position: sticky;
    top: 0;
    z-index: 998;
  }

  &-content-wrapper {
    overflow: auto;
    padding: 0 30px;
    margin-left: -30px;
    margin-right: -30px;
  }

  .btn-group-deliveries-container {
    @extend .sticky-top;

    top: 150px;
    width: calc(100% + 30px);
    background-color: $bgColor;

    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px;
    z-index: 999;
    position: sticky;
    text-align: center;
  }

  .btn-group-deliveries {
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 100%;

    .btn {
      text-transform: none;
      white-space: nowrap;
    }

    .btn-outline-secondary {
      background-color: transparent;
      color: $textColorNotActive;
      border-radius: 0 !important;
      margin-right: 1vw;
      font-size: 14px;
      border: 0;
      border-bottom: 1px solid transparent !important;

      &:focus, &.active:focus {
        box-shadow: none !important;
        color: $primaryText !important;
      }

      &:hover, &.active {
        background-color: transparent !important;
        color: $textColor !important;
        border: 0;
        border-bottom: 1px solid $primaryColor !important;
      }
    }
  }

  table.basic.deliveries.table {
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      display: none;
      th {
        border: 0 !important;
      }
    }

    tbody {
      tr {
        box-shadow: 0 6px 10px 2px rgba(0,0,0,0.02);
        transition: box-shadow 0.3s ease-in-out;

        td {
          padding: 10px 8px 10px 8px;
          background: #FCFCFD;
          border: 0;

          &.actions {
            min-width: 110px;
          }

          &:first-child {
            min-width: 8px;
            max-width: 8px;
            padding-right: 0;
            padding-left: 0;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            &:before {
              content: '';
              position: absolute;
              display: block;
              background: transparent;
              width: 3px;
              height: 44px;
              transition: 0.3s ease-in-out;
            }
          }

          &:last-child {
            -moz-border-radius-topright: 8px;
            -moz-border-radius-bottomright: 8px;
            -webkit-border-top-right-radius: 8px;
            -webkit-border-bottom-right-radius: 8px;
            padding-right: 15px;
          }

          &.checkbox-wrapper {
            min-width: 35px;

            .custom-checkbox {
              margin-top: 5px;
            }
          }

          .status {
            margin-top: 5px;
            font-size: 12px;
            color: $textColor;
            strong {
              font-size: 14px;
              color: $primaryText;
              font-weight: 500;
            }

            &-pl {
              padding-left: 18px;
            }
          }

          .agent {
            color: $textColor;
            font-size: 12px;
            text-align: left;
            margin-top: 6px;
            img {
              margin-bottom: 0;
              max-width: 120px;
              max-height: 20px;
              display: block;
            }
          }

          .recipient {
            margin-top: 5px;
            font-size: 12px;
            color: $textColor;
            span {
              font-size: 14px;
              color: $primaryText;
            }
          }

          .cod {
            margin-top: 5px;
            font-size: 12px;
            color: $textColor;
            strong {
              font-size: 14px;
              color: $primaryText;
              font-weight: 700;
            }
          }

          .rating {
            span {
              color: $grey-alt;
            }

            .icon {
              margin-bottom: 1px;
              margin-right: 4px;
            }

            &.off {
              .icon {
                --color: $grey;
              }
            }
          }
        }

        &.selected {
          background: #FFFFFF;
          box-shadow: 0 6px 10px 2px rgba(0,0,0,0.07);
          transition: box-shadow 0.3s ease-in-out;

          td:first-child:before {
            background: $primaryColor;
            transition: 0.3s ease-in-out;
          }

        }
      }

    }
  }
}

table.basic.import-csv.table {
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }
}

.deliveries {
  .action-bar {
    width: calc(100% - 80px);
    left: 80px;
  }
}
