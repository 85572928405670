@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~foxdeli-shared/scss/colors';

@include ds {
  font-size: 0.875rem;

  // Generate bootstrap grid override (use 24px gutter instead of the 30px default)
  .container {
    $grid-gutter-width: 24px;

    @include make-container($grid-gutter-width);
    @include make-grid-columns(12, $grid-gutter-width, $grid-breakpoints);

    .row {
      @include make-row($grid-gutter-width);
    }
  }

  // TODO: loop over all custom colours and generate text- classes
  .text-grey {
    color: $grey;
  }

  .text-grey-light {
    color: $grey-alt;
  }

  .card {
    background: rgba($white, 0.8);
    border-radius: 4px;
    border: 1px solid $grey-light;
    box-shadow: none;
  }

  .progress {
    background-color: rgba($grey-light, 0.5);
    height: 8px;

    .progress-bar {
      // TODO: Use @include border-radius($progress-border-radius)
      border-radius: 0.25rem;
    }
  }

  .bg-success {
    background-color: $green !important;
  }

  .bg-danger {
    background-color: $red !important;
  }

  .bg-info {
    background-color: $blue-70 !important;
  }

  .bg-warning {
    background-color: $magenta !important;
  }

  .text-success {
    color: $green !important;
  }

  .text-danger {
    color: $red !important;
  }

  .font-weight-medium {
    font-weight: 500;
  }

  h3 {
    color: $grey;
    text-transform: none;
    font-size: 1.625rem;
  }

  // "_ds" class can be either applied to popover or the popover can exist within "_ds" scope
  &.popover,
  .popover,
  .tooltip {
    font-family: 'Tofino', sans-serif;

    &.hide-arrow {
      .arrow {
        display: none;
      }
    }
  }

  .popover {
    border-color: $blue;
    border-radius: 6px;
    box-shadow: 0 15px 30px rgba(52, 58, 64, 0.05);

    .react-datepicker {
      font-family: inherit;
      border: none;

      &__month-container {
        float: none;
        border-bottom: 1px solid $grey-light;
      }

      &__current-month {
        font-size: 0.8125rem;
        color: $black;
      }

      &__header {
        background: $blue-10;
        border-color: $blue-70;
      }

      &__navigation--previous {
        border-right-color: $grey-50;
      }

      &__navigation--next {
        border-left-color: $grey-50;
      }

      &__day-name,
      &__day,
      &__time-name {
        color: $grey;
        font-weight: 500;
        font-size: 0.75rem;
      }

      &__day,
      &__time-name {
        color: $grey-alt;
      }

      &__day--today {
        color: $grey;
        font-weight: 700;
      }

      &__day--in-selecting-range,
      &__day:hover {
        background: $blue-50;
        color: $white;
      }

      &__day--disabled {
        color: $grey-light;

        &:hover {
          cursor: default;
          background: inherit;
          color: $grey-light;
        }
      }

      &__day--in-range,
      &__day--selected {
        background: $blue;
        color: $white;
        font-weight: 700;
      }
    }
  }

  .nav-tabs {
    border: none;

    .nav-item {
      flex-grow: 1;

      .nav-link {
        background: none;
        border: none;
        border-bottom: 2px solid rgba($grey-50, 0.1);
        border-radius: 0;
        color: $grey-50;
        height: 50px;
        font-weight: 700;
        width: 100%;
        z-index: 1;

        &.active {
          border-bottom-color: $blue;
          color: $grey;
        }
      }
    }
  }
}

// Icomoon icon styles
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
