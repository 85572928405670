@import '~foxdeli-shared/scss/colors';

.banner {
  align-items: center;
  border-radius: 4px;
  color: $white;
  display: flex;
  font-size: 0.9375rem;
  flex-flow: row;
  justify-content: center;
  min-height: 56px;
  padding: 16px;

  & + & {
    margin-top: -8px;
  }

  &.primary {
    box-shadow: 0 8px 16px -8px transparentize($grey-alt, 0.4);
    background-color: $grey-alt;

    :global .btn.banner-btn {
      -webkit-appearance: none;

      &:focus,
      &:hover,
      &:active {
        background-color: $white;
        color: $grey-alt;
      }
    }
  }

  &.info {
    box-shadow: 0 8px 16px -8px transparentize($magenta, 0.4);
    background-color: $magenta;

    :global .btn.banner-btn {
      -webkit-appearance: none;

      &:focus,
      &:hover,
      &:active {
        background-color: $white;
        color: $magenta;
      }
    }
  }

  &.success {
    background-color: $green;
    box-shadow: 0 8px 16px -8px transparentize($green, 0.4);
  }

  &.warning {
    background-color: $magenta;
    box-shadow: 0 8px 16px -8px transparentize($magenta, 0.4);

    :global .btn.banner-btn {
      -webkit-appearance: none;

      &:focus,
      &:hover,
      &:active {
        background-color: $white;
        color: $magenta;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.error {
    background-color: $red;
    box-shadow: 0 8px 16px -8px transparentize($red, 0.4);
  }

  // TODO: Most of these overrides shouldn't be necessary as soon as we remove legacy styles
  :global .btn.banner-btn {
    background: none;
    color: currentColor;
    border: 1px solid currentColor;
    border-radius: 8px;
    flex-shrink: 0;
    font-size: 0.9375rem;
    font-weight: normal;
    margin: -8px 0 -8px 16px;
    padding: 13px 16px 11px 16px;
    text-transform: none;
    -webkit-appearance: none;
  }
}
