@import '~foxdeli-shared/scss/colors';
@import 'src/sass/variables';

.simple-file-input {
  .simple-file-input-wrapper {
    background: $blue-10;
    margin-top: 0;
    margin-bottom: 0;

    border: 1px dashed $blue;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    min-height: 120px;
    max-height: 120px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }

  &-placeholder {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    display: block;
  }

  &-preview {
    margin: 0 auto;
    display: block;
    max-height: 118px;
    max-width: 90%;
  }

  &-label {
    width: 100%;

    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }

  &-erase {
    float: right;
    cursor: pointer;
    color: $red;
    font-weight: bold;
  }

  .invalid-feedback {
    display: block;
  }

  &:not(&--disabled) {
    &:hover {
      .simple-file-input-wrapper {
        background: #d2d5fd;
        border: 1px dashed $grey;
        transition: background-color 0.2s linear;
      }
    }
  }

  &--disabled {
    .simple-file-input-wrapper {
      background: $grey-lighter;
      border: 1px dashed $grey;
      cursor: default;
    }

    .simple-file-input-placeholder {
      opacity: .6;
      filter: grayscale(100%);
    }
  }
}
