@import '~foxdeli-shared/scss/colors';

$card-padding: 1.25rem;

.branding {
  &.container {
    max-width: 100%;
    min-height: 150px;
  }

  &-form-controls {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    button {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }

  &-required-field {
    color: $red;
    padding: 0 2px;
  }

  &-hr-90 {
      width: 90%;
  }

  h3 {
    font-weight: 500;
    font-size: 1.0625rem;
    text-transform: none;
  }

  &-subtitle {
    color: $grey;
    font-size: 1.0625rem;
  }

  &-description {
    font-size: .9375rem;
  }

  &-technical-info {
    font-size: .8125rem;
    padding: 0 1rem;
  }

  &-description-list {
    font-size: .9375rem;

    dd + dt {
      margin-top: 15px;
    }

    dt {
      font-weight: 500;
    }

    dd {
      color: $grey-black;
      margin-bottom: 0;
    }
  }

  input.form-control {
    border-right: 1px solid #e9ecef;

    &:focus {
      border-right: 1px solid #65C514;
    }

    &.is-valid {
      border-right: 1px solid #28a745;
      border-radius: 4px;
    }

    &.is-invalid {
      border-right: 1px solid #dc3545;
      border-radius: 4px;
    }
  }

  .card {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
    border-radius: 8px;

    &-delete {
      color: $red;
      float: right;
      cursor: pointer;
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: calc((#{$card-padding} / 2) * -1);
      margin-right: calc((#{$card-padding} / 2) * -1);
    }
  }

  &-sticky-preview {
    position: sticky;
    top: 150px;
  }
}