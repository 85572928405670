.fixed-top {
  background-color: $bgColor;
  height: 9.375rem; // 150px; will get rid of fixed topbar in redesigned version
  margin-left: 80px; // 250px is be the original value - use it when you want revert full size menu
}

.content-wrapper {
  margin-top: 9.125rem; // can be overriden in MainBody wrapperHeight prop
  padding-bottom: 60px;
  width: 100%;
}

.user-menu {
  font-weight: 300;
  font-size: 14px;
  color: $primaryText !important;

 &.dropdown-toggle {
   @include font-tofino;
   font-weight: 300;
   font-size: 16px;

   span {
     padding: 6px;
     display: inline-block;
     margin-right: 6px;
     font-size: 14px;
     width: 34px;
     height: 34px;
     line-height: 23px;
     text-align: center;
   }
 }
}

.initialsIconWrapper {
  @extend .d-inline-block;

  &.table {
    position: relative;
    left: -60px;
  }
}

.initialsIcon {
  background-color: #D8D8D8;
  border-radius: 50px;
  padding: 8px 9px;
  color: white;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  border: 1px solid #fff;
}

.multiple::before {
  content: " ";
  background-color: #D8D8D8;
  border-radius: 50px;
  padding: 6px;
  color: white;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 4px;
  top: 0;
  z-index: -1;
}

.iconset {
  .initialsIcon {
    position: inherit;
  }

  .another {
    background-color: #D8D8D8;
    border-radius: 50px;
    padding: 6px;
    color: white;
    display: inline-block;
    width: 24px;
    height: 24px;

    left: 4px;
    top: 0;
    z-index: -1;
  }
}

@media (max-width: 990px) {
  #topbar-search {
    display: block;
  }
}
