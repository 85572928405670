table.basic.batches.table {
  border-collapse: separate;
  border-spacing: 0 10px;

  thead {
    display: none;
    th {
      border: 0 !important;
    }
  }

  tbody {
    tr {
      box-shadow: 0 6px 10px 2px rgba(0,0,0,0.02);
      transition: box-shadow 0.3s ease-in-out;

      td {
        padding: 16px 12px 16px 12px;
        background: #FCFCFD;
        border: 0;

        &:first-child {
          padding-left: 30px;
          -moz-border-radius-topleft: 8px;
          -moz-border-radius-bottomleft: 8px;
          -webkit-border-top-left-radius: 8px;
          -webkit-border-bottom-left-radius: 8px;
        }

        &:last-child {
          -moz-border-radius-topright: 8px;
          -moz-border-radius-bottomright: 8px;
          -webkit-border-top-right-radius: 8px;
          -webkit-border-bottom-right-radius: 8px;
          padding-right: 30px;
        }

        .deliveries-count {
          margin-top: 6px;
        }

        .agent {
          margin-top: 13px;
          img {
            max-width: 120px;
            max-height: 25px;
            display: block;
          }
        }

        .collection-place, .created {
          margin-top: 5px;
          font-size: 12px;
          color: $textColor;
          span {
            font-size: 14px;
            color: $primaryText;
          }
        }

        &.actions {
          width: 200px;
        }
      }

      &.selected {
        background: #FFFFFF;
        box-shadow: 0 3px 20px 10px rgba(0,0,0,0.07);
        transition: box-shadow 0.3s ease-in-out;
      }
    }
  }
}
