@import '~foxdeli-shared/scss/colors';

.mainTitle {
  color: $grey;
  min-width: 800px;

  :global .action-group {
    .btn-group {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  :global .main-title-tag {
    background: transparentize($magenta, 0.9);
    border-radius: 10px 10px 10px 0;
    color: $magenta;
    font-size: 12px;
    margin-left: 6px;
    padding: 7px 8px 5px 8px;
    text-transform: uppercase;
    transform: translate(0, -60%);
  }
}
