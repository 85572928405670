@font-face {
  font-family: Tofino;
  font-weight: 200;
  src: url("/assets/fonts/TofinoPersonal-Thin.otf") format("opentype");
}

@font-face {
  font-family: Tofino;
  font-weight: 300;
  src: url("/assets/fonts/TofinoPersonal-Book.otf") format("opentype");
}


@font-face {
  font-family: Tofino;
  font-weight: 400;
  src: url("/assets/fonts/TofinoPersonal-Regular.otf") format("opentype");
}

@font-face {
  font-family: Tofino;
  font-weight: 500;
  src: url("/assets/fonts/TofinoPersonal-Medium.otf") format("opentype");
}

@font-face {
  font-family: Tofino;
  font-weight: 700;
  src: url("/assets/fonts/TofinoPersonal-Bold.otf") format("opentype");
}

@mixin font-tofino {
  font-family: 'Tofino', "Helvetica Neue", Arial, sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');