@import '~foxdeli-shared/scss/colors';

.banner-items {
  border: none;

  h3 {
    font-size: 0.9375rem;
    text-transform: none;
  }

  &-description {

  }

  .card {
    box-shadow: none;
  }

  .banner-item {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
    border-radius: 8px;

    &-delete {
      color: $red;
      float: right;
      cursor: pointer;
    }
  }
}