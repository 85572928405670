@import '~foxdeli-shared/scss/colors';

.modal-with-preview {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  .content-preview {
    border: 10px solid gainsboro;
    border-radius: 4px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, $grey, $grey-alt);
    opacity: 0;
    transition: opacity 0.2s ease-out;
    box-shadow: $grey 0 0 3px 0px;
    border-radius: 4px;
  }

  &::after {
    content: "";
    font-size: 1rem;
    color: $white;
    opacity: 0;
    background: url("/assets/img/show-preview.png") no-repeat center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-out;
  }

  &:hover {
    &::before {
      opacity: 0.65;
      transform: scale(1);
    }

    &::after {
      opacity: 1;
    }
  }
}

.modal-with-preview-content {
  width: 100%;
  height: 100%;

  svg{
    width: 100%;
    height: 100%;
    background: gainsboro;
    padding: 0.5rem;
  }
}