.modal {
  &-header {
    padding: 2rem;
    color: $primaryText;
    border-bottom: none;

    .close {
      margin: -1.5rem -1rem -1rem auto;
      span {
        font-weight: initial;
        font-size: 2.5rem;
      }
    }
  }
  &-body {
    padding: 2rem;
  }
  &-content {
    border-radius: 10px;
  }

  .modal-drop {
    .modal-body {
      padding-top: 8em;
      padding-bottom: 8em;

      img {
        margin-bottom: 40px;
      }

      h4 {
        font-size: 36px;
        font-weight: normal;
        color: #1B2E34;
      }
      p {
        font-size: 16px;
        margin-top: 15px;
        color: #1B2E34;
      }
    }
  }

  .react-datepicker-popper {
    width: 330px;
  }
}
