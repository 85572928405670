/* ---------------------------------------------------
    DRAG & DROP ZONE
----------------------------------------------------- */
.dropzone {
  color: #ccc;
  border: dashed 2px #ccc;
  text-align: center;
  margin: 15px 0 15px 0;
  height: 108px;

  &:focus {
    outline: none;
  }
  &.active {
    color: #28a745;
    border: dashed 2px #28a745;
  }

  &.reject {
    color: #dc3545;
    border: dashed 2px #dc3545;
  }

  i {
    display: block;
    padding: 15px 0 15px 0;
  }
}