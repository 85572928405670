@import '~foxdeli-shared/scss/colors';
@import 'src/sass/variables';

.simple-switch-wrapper {
  display: flex;
  align-items: center;
  align-content: space-between;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .simple-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    min-width: 52px;
    height: 28px;

    &-input {
      display: none;
    }

    &-slider {
      position: absolute;
      cursor: pointer;
      border-radius: 34px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $grey url('/assets/img/cross_bolder.png') no-repeat 28px center;
      transition: .4s;
    }

    &-slider:before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
    }

    &-checked .simple-switch-slider {
      background: $blue url('/assets/img/check_bolder.png') no-repeat 8px center;
    }

    input:focus .simple-switch-slider {
      box-shadow: 0 0 1px $blue;
    }

    &-checked .simple-switch-slider:before {
      transform: translateX(24px);
    }
  }
}