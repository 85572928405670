@import '~foxdeli-shared/scss/colors';

.progress {
  flex-grow: 2;

  :global .progress-bar {
    background: $blue-70;
  }
}

.progressRow {
  border-bottom: 1px solid rgba($grey, 0.1);
  color: $grey-alt;
  padding: 12px 0;

  &.compact {
    padding: 8px 0;
  }

  &:nth-child(even) {
    .progress {
      :global .progress-bar {
        background: $blue-50;
      }
    }
  }

  &.disabled {
    color: $grey-50;

    :global .progress {
      :global .progress-bar {
        background-color: rgba($grey-light, 0.5);
      }
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
