$white: #ffffff;
$black: #000;

$grey-black: #17171E;
$grey: #343A40;
$grey-alt: #6C757D;
$grey-50: #A3A6AA;
$grey-light: #D6DBDF;
$grey-lighter: #F3F3F7;
$grey-soft: #F8F9FA;
$grey-softer: #FDFDFE;

$blue: #3745FF;
// TODO: Rename? Missing from design system
$blue-dark: #3642C6;
$blue-70: #6D78FE;
$blue-50: #B6BCFE;
$blue-10: #E4E6FE;

$orange: #FF5900; // #E71D73
$orange-50: #FFAC80;
$orange-10: #FFEEE6;

$magenta: #E71D73;
$magenta-70: #F47FB3;
$magenta-50: #F7A1C1;
$magenta-10: #FEE8F0;

$red: #E21111;
$red-50: #F08787;
$red-20: #F9CFCF;
$red-10: #FDE8E8;

$green: #65C514;
$green-50: #B2E289;
$green-20: #E0F3D0;
$green-10: #F0F9E8;

$yellow: #E9B117;
$yellow-50: #FFE59B;
$yellow-20: #FFF4D7;
$yellow-10: #FFFAEB;

$navy: #0D1465;
$navy-50: #8689B2;
$navy-20: #CFD0E0;
$navy-10: #E7E7F0;
