.search-bar {

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: $navColor;

  .form-control {
    background-color: $navColor;
  }

  input {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: none;
    height: 40px;
    font-size: 14px;

    padding: 0 0 0 62px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTlweCIgaGVpZ2h0PSIxOXB4IiB2aWV3Qm94PSIwIDAgMTkgMTkiIHZlcnNpb249IjEuMSI+ICA8ZyBpZD0iTWFzdGVyc2hlZXQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8ZyBpZD0iTWFzdGVyc2hlZXQtMS4wIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDk5LjAwMDAwMCwgLTE1NzUwLjAwMDAwMCkiIGZpbGw9IiMxQjJFMzQiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgPGcgaWQ9IlA3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4wMDAwMDAsIDEzMjIxLjAwMDAwMCkiPiAgICAgICAgPGcgaWQ9IlRvcC1NZW51IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0ODAuMDAwMDAwLCAyNTE4LjAwMDAwMCkiPiAgICAgICAgICA8ZyBpZD0iU2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMC4wMDAwMDAsIDExLjAwMDAwMCkiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOC44MzU2NjIgMTguMDMxMDQyMUwxNC4yMTczNDMxIDEzLjQxMzc0NzJDMTUuNDU2MTk4NyAxMS45ODk4MDA0IDE2LjIwNjI1NDIgMTAuMTMxOTI5IDE2LjIwNjI1NDIgOC4xMDEzMzAzOCAxNi4yMDYyNTQyIDMuNjMxNDg1NTkgMTIuNTY5NzQ5NCAwIDguMTAzMTI3MDggMCAzLjYzMjI5MDk3IDAgMCAzLjYzNTY5ODQ1IDAgOC4xMDEzMzAzOCAwIDEyLjU2Njk2MjMgMy42MzY1MDQ3NyAxNi4yMDI2NjA4IDguMTAzMTI3MDggMTYuMjAyNjYwOCAxMC4xMzQxNzYxIDE2LjIwMjY2MDggMTEuOTkyNDU5NSAxNS40NTI3NzE2IDEzLjQxNjcyMjEgMTQuMjE0MTkwN0wxOC4wMzUwNDEgMTguODMxNDg1NkMxOC4xNDQ1OTk3IDE4Ljk0MTAyIDE4LjI5MjA4MjUgMTkgMTguNDM1MzUxNSAxOSAxOC41Nzg2MjA1IDE5IDE4LjcyNjEwMzMgMTguOTQ1MjMyOCAxOC44MzU2NjIgMTguODMxNDg1NiAxOS4wNTQ3NzkzIDE4LjYxMjQxNjkgMTkuMDU0Nzc5MyAxOC4yNTAxMTA5IDE4LjgzNTY2MiAxOC4wMzEwNDIxWk0xLjEzMzUxMDc2IDguMTAxMzMwMzhDMS4xMzM1MTA3NiA0LjI1OTIwMTc3IDQuMjYwMTQ2MzcgMS4xMzc0NzIyOCA4LjA5ODkxMzI4IDEuMTM3NDcyMjggMTEuOTQxODk0IDEuMTM3NDcyMjggMTUuMDY0MzE1OCA0LjI2MzQxNDYzIDE1LjA2NDMxNTggOC4xMDEzMzAzOCAxNS4wNjQzMTU4IDExLjkzOTI0NjEgMTEuOTQxODk0IDE1LjA2OTQwMTMgOC4wOTg5MTMyOCAxNS4wNjk0MDEzIDQuMjYwMTQ2MzcgMTUuMDY5NDAxMyAxLjEzMzUxMDc2IDExLjk0MzQ1OSAxLjEzMzUxMDc2IDguMTAxMzMwMzhaIiBpZD0iU2hhcGUiLz4gICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICAgIDwvZz4gICAgPC9nPiAgPC9nPjwvc3ZnPg==");
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: 22px 10px;
  }

  .rbt-input-main[aria-expanded="true"] {
      -webkit-border-radius: 20px 20px 0 0;
      -moz-border-radius: 20px 20px 0 0;
      border-radius: 20px 20px 0 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  }

  .dropdown-menu {

    border: none;
    border-radius: 0 0 20px 20px;
    margin: 0;
    box-shadow: 0 7px 11px 2px rgba(0, 0, 0, 0.04);
    border-top: 1.5px solid rgb(195, 195, 195);

    li {
      border-bottom: 1.5px solid #f3f3f7;

      &.rbt-menu-pagination-option {
        text-align: center;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover a {
        color: $magenta;
      }

      &:hover a.disabled {
        color: initial;
      }

    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  .dropdown-item {
    padding: 0.8rem 1.5rem;

    .row {
      .col-1, .col-3, .col-4 {
        padding: 0;
      }
    }

    &:active, &.active {
      color: $magenta;
    }

    &.disabled {
      svg.svg-inline--fa {
        margin-right: 13px;
      }
    }
  }
}

