.col-toggle {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 200px;
  overflow-y: -webkit-paged-x;
  font-weight: bold;
  div {
    width: 400px;
    display: block;
  }
}

.col-invisible {
  overflow: hidden;
  max-width: 0px !important;
  padding: 0 !important;
  width: 10px !important;
  max-height: 0px;
  overflow-y: -webkit-paged-x;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.card {
  background: $bgCardColor;
  box-shadow: 0 6px 10px 2px rgba(0,0,0,0.02);
  border-radius: 8px;
  border-color: transparent;

  &.csv-import {
    position: sticky;
    top: 0;

    margin: 24px 0 24px 0;

    .nav-item {
      line-height: inherit;
      margin-bottom: 0.75rem;
    }
    .card-body {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  &-title {
    padding-left: 0;
  }

  .small-title.first {
    margin-top: 0px;
  }

  .small-title {
    text-transform: uppercase;
    font-size: 12px;
    color: $textMuted;
    padding: 2px 0 2px;
    margin: 15px 0 8px 0;
  }

  .card-header {
    background-color: transparent;
    padding-top: 25px;
    border-bottom: 0px none;
    position: relative;

    &.with-buttons {
      @extend .d-flex;
      @extend .justify-content-between;
      @extend .align-items-center;

      h4 {
        font-size: 16px;
      }

      .right {
        @extend .d-flex;
      }
    }

    h3 {
      margin-bottom: 15px;
      max-width: 230px;
    }

    .spinner-border {
      font-size: initial;
      color: $loaderColor !important;
      width: 1.5rem;
      height: 1.5rem;
      border-width: 0.2em;
    }

    hr {
      border: 0;
      clear: both;
      display: block;
      width: 100%;
      padding-left: 0;
      background-color: #D8D8D8;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }

  .card-body {
    background-color: transparent;

    h1 {
      color: $primaryText;
      font-size: 2rem;
    }
  }

  .card-footer {
    border-top: none;
    background-color: transparent;
  }

  .nav-item {
    line-height: 50px;
    .active {
      color: $primaryText;
    }
  }
}

.right-col-spacing{
  padding-bottom: 20px;
}