.collectionPlaces {
  tr {
    td:first-child {
      font-weight: bold;
    }
  }

  td {
    .btn-link-action {
      width: 24px;
      height: 24px;
    }
  }

  .inactive {
    td {
      color: #A9A9A9 !important;

      .btn-link-action:nth-of-type(1) {
        display: none;
      }
    }
  }

  .active {
    td {
      .btn-link-action:nth-of-type(2) {
        display: none;
      }
    }
  }
}
