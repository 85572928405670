.agents {
  td {
    vertical-align: middle;

    img {
      max-width: 150px;
      max-height: 30px;
    }

    &:last-child {
      width: 100px;
    }

    span[id^="Tooltip-"] {
      color: initial;
      font-weight: normal;
      padding-left: 10px;
    }
  }
}
