.form-control {
  border-radius: 4px;
  border: 1px solid $inputBorder;

  &:focus {
    background-color: $white;
    border-color: $primaryColor;
    box-shadow: none;
  }

  &::placeholder {
    color: $textColorNotActive;
  }

  &.text-center {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.input-group-append {
  .select__control {
    border-left: 0;
    border-color: $inputBorder;
    min-width: 100px;
  }
}

input::placeholder,
.select__placeholder {
  color: #cccccc !important; // TODO: !important ain't cool
}

.form-check-input {
  &.custom-checkbox {
    &.strong {
      color: $primaryText;
    }
  }

  &.full-width {
    .custom-control-label {
      display: block;
      width: 100%;
    }
  }
}

.details {
  margin-left: 2.5rem;
}

.form-check, .form-group {
  label{
    margin-top: 10px;
  }
}
