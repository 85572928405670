@import './src/sass/_variables.scss';

// style reset for sub section
.settings-branding-marketing-campaigns {
  .settings-right {
    .card {
      box-shadow: none;
      border-radius: initial;
      border-color: transparent;
      background: none;

      .card-body {
        padding: 0;
      }
    }
  }
}

.marketing-campaigns {
  &-title {
    padding: 1.25rem 0;
    margin-bottom: 1rem;
    box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    border-color: transparent;
    background: $bgCardColor;
  }
}