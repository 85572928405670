@import '~foxdeli-shared/scss/colors';

.row {
  td.dashedBorder {
    border-left: 1px dashed $grey-light;
  }

  td.solidBorder {
    border-right: 1px solid $grey-light;
  }
}

.popoverTrigger {
  color: $blue;
  padding-bottom: 2px;
  cursor: pointer;
}

.durationPopover {
  min-width: 250px;

  :global .popover-body {
    padding: 1rem;
  }
}

.progressList {
  :global .progress-list-label,
  :global .progress-list-value {
    min-width: 55px;
  }
}

.rating {
  &.disabled {
    --color: $grey-50;
    color: $grey-50;
  }
}
